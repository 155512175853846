html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow: auto;
}

.app {
  height: 100%;
  width: 100%;
  overflow: hidden;
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

.menu {
  position: fixed;
  top: 0;
  font-size: large;
  width: 100%;
  display: block;
  text-align: center;
  background-color: #3c5560;
  color: white;
}

.menu ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  background-color: #3c5560;
  position: relative;
}

.menu li {
  display: inline-block;
  margin-left: 1px;
  margin-right: 1px;
}

.menu li a {
  color: white;
  display: block;
  min-width: 140px;
  line-height: 50px;
  padding: 8px;
  text-decoration: none;
  text-align: center;
  transition: 0.3s;
}

.menu a:hover {
  background-color: #4b6a78;
}

.menu .active {
  background: #2b3e46;
}

.language-selector {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 100;
}

.language-selector button {
  display: block;
  border: 0;
  width: 30px;
  height: 22.5px;
  box-shadow: none;
  border-radius: 0px;
  z-index: 200;
}

.language-selector>div {
  float: left;
}

.content {
  margin-top: 100px;
  background-color: white;
  color: black;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.form-parent {
  width: 100%;
  display: grid;
  justify-content: center;
  align-content: center;
}

form {
  display: grid;
  grid-gap: 16px;
  justify-content: center;
  align-content: center;
  margin-bottom: 20px;
}

label {
  text-align: right;
  grid-column: 1/2;
}

input {
  grid-column: 2/3;
}

button {
  grid-column: 1/3;
}

.page {
  page-break-after: always;
}

.printable {
  font-size: 28px;
}

td.abcs-row {
  border: 1px solid black;
  border-collapse: collapse;
}

td.words {
  white-space: nowrap;
}

td.abcs-row.lowercase, td.abcs-row.words {
  font-family: 'Comic Sans MS', 'Comic Sans';
}

td.abcs-row.bopomo {
  font-family: 'MingLiU' '明體' '宋体' 'MS Mincho';
}

td.metadata-row {
  border: 1px solid black;
  border-collapse: collapse;
}

table,
tr {
  border: 1px solid black;
  border-collapse: collapse;
}

table {
  table-layout: fixed;
  width: 500px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.freetext-parent {
  display: flex;
  align-items: center;
  justify-content: center;
}

textarea {
  resize: none;
  font-size: 20px;
  overflow: hidden
}

@media all {
  .page-break {
    display: none;
  }
}

@media print {

  html,
  body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }
}

@media print {
  .page-break {
    margin-top: 1rem;
    display: block;
    page-break-before: auto;
  }
}

@page {
  size: A4 portrait;
  margin-top: 20mm;
}