.game-menu {
  select {
    margin-right: 0.1em;
  }

  select.difficulty {
    font-size: 2em;
  }

  button {
    font-size: 2em;
    margin-left: 0.1em;
    margin-right: 0.1em;
    border-radius: 12px;

    font-size: 2em;
    margin-left: 0.1em;

    transform: translateY(0);
    transition:
      transform 150ms,
      box-shadow 150ms;
  }

  button:hover {
    box-shadow: rgba(0, 0, 0, 0.15) 0 3px 9px 0;
    transform: translateY(-2px);
  }
}
